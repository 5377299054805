import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";

import { HeaderProps, Antibiotic } from "../types/AntiBiogramTypes";
import { getAntibioticHeaderStyle } from "../util/AntiBiogramUtils";

const StyledTableCell = styled(TableCell)({
  border: "3px solid silver",
  fontFamily: "Arial",
  fontSize: "18px",
  textAlign: "center",
  padding: "10px",
});

const VerticalStyledTableCell = styled(StyledTableCell)({
  height: "150px",
  fontSize: "18px",
  "& div": {
    writingMode: "vertical-rl",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    whiteSpace: "nowrap",
    display: "inline-block",
    textAlign: "center",
  },
});

const NoBorderCell = styled(TableCell)({
  border: "none",
  textAlign: "center",
  fontFamily: "Arial",
  fontSize: "18px",
  padding: "10px",
});

/** HEADER COMPONENT */
const Header: React.FC<HeaderProps> = ({ siteGroup, antibiotics }) => {
  const unrestricted = antibiotics.filter((item: Antibiotic) => item.antibioticType === 1);
  const restricted = antibiotics.filter((item: Antibiotic) => item.antibioticType === 2);

  return (
    <TableHead>
      {/* First Row */}
      <TableRow>
        <NoBorderCell colSpan={3}>{siteGroup} Isolates</NoBorderCell>
        <StyledTableCell colSpan={unrestricted.length} style={{ background: "white" }}>
          Unrestricted Antibiotics
        </StyledTableCell>
        <StyledTableCell colSpan={restricted.length} style={{ background: "#e6e6e6" }}>
          Restricted Antibiotics
        </StyledTableCell>
      </TableRow>

      {/* Second Row */}
      <TableRow>
        <StyledTableCell rowSpan={2}>Organism Group</StyledTableCell>
        <VerticalStyledTableCell rowSpan={2}>
          <div>No. Isolates</div>
        </VerticalStyledTableCell>
        <VerticalStyledTableCell rowSpan={2}>
          <div>&nbsp;&nbsp;</div>
        </VerticalStyledTableCell>
        {unrestricted.map((antibiotic) => {
          const headerStyle = getAntibioticHeaderStyle(antibiotic.antibioticType, antibiotic.restriction);
          return (
            <VerticalStyledTableCell key={antibiotic.id} style={headerStyle}>
              <div>
                {antibiotic.name} ({antibiotic.groupCode})
              </div>
            </VerticalStyledTableCell>
          );
        })}
        {restricted.map((antibiotic) => {
          const headerStyle = getAntibioticHeaderStyle(antibiotic.antibioticType, antibiotic.restriction);
          return (
            <VerticalStyledTableCell key={antibiotic.id} style={headerStyle}>
              <div>
                {antibiotic.name} ({antibiotic.groupCode})
              </div>
            </VerticalStyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
export default Header;
