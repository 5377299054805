import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import styles from "../AntiBiogramReport.module.css";

import { AntibiogramRow, Antibiotic, Isolate } from "../types/AntiBiogramTypes";
import { computeCellStyle, computeCellContent } from "../util/AntiBiogramUtils";

const StyledTableCell = styled(TableCell)({
  border: "3px solid silver",
  fontFamily: "Arial",
  fontSize: "18px",
  textAlign: "center",
  padding: "10px",
  whiteSpace: "nowrap",
});

const FirstRow: React.FC<{ row: AntibiogramRow; antibiotics: Antibiotic[]; rowIndex: number }> = ({
  row,
  antibiotics,
  rowIndex,
}) => {
  return (
    <TableRow>
      {/* First column: Organism Name (Merged across two rows) */}
      <StyledTableCell rowSpan={2} className={styles.noBgColor}>
        {row.organismName}
      </StyledTableCell>
      {/* Second column: No. Isolates (Merged across two rows) */}
      <StyledTableCell rowSpan={2} className={styles.noBgColor}>
        {row.sumOfIsolatesPerAntimicrobial}
      </StyledTableCell>
      {/* Third column: Blank header, but first row has %S */}
      <StyledTableCell className={styles.noBgColor}>%S</StyledTableCell>
      {/* Antibiotic columns */}
      {antibiotics.map((antibiotic) => {
        const isolate: Isolate | undefined = row.isolatesList.find(
          (isolate) => isolate.antimicrobial === antibiotic.groupCode,
        );
        const percOfIsoSentvty = isolate?.percOfIsoSentvty || "-";
        const noOfIsolates = isolate?.isolates?.toString() || "-";
        const resultType = isolate?.result || "-";

        const cellStyle = computeCellStyle(resultType, percOfIsoSentvty);
        const cellContent = computeCellContent(resultType, percOfIsoSentvty, noOfIsolates);

        return (
          <StyledTableCell key={`${rowIndex}-${antibiotic.id}`} className={styles[cellStyle]}>
            {cellContent}
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
};

export default FirstRow;
