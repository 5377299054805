import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Box, Typography, Grid, Table, TableContainer, Button, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { MainNavigation } from "../../MainNavigation/MainNavigation";
import HospitalReportsStyledHeader from "../HospitalReportsStyledHeader";
import AppContext from "../../../context/AppContext";
import authService from "../../../services/authService";

// Import Material UI Icons
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";

import { AntiBiogramReportProps } from "./types/AntiBiogramTypes";
import { getReportHeading } from "./util/AntiBiogramUtils";
import GET_ANTIBIOTIC_AND_ANTIBIOGRAM_REPORT from "./GetAntibioticAndAntibiogramReportQuery";
// import { headerData, bodyData } from "./types/JsonData";

import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";

import Loading from "../../Loading/Loading";

import styles from "./AntiBiogramReport.module.css";

const AntiBiogramReportPage: React.FC<AntiBiogramReportProps> = () => {
  const { isDesktop } = useContext(AppContext);
  const mobility = authService.isMobility();
  const offsetHeight = !isDesktop || mobility ? 162 : 138;
  const isSmallScreen = useMediaQuery("(max-width: 900px)");

  const { filename } = useParams<{ filename: string }>();

  // Always call useQuery, even if loading or error occurs
  const { loading, error, data } = useQuery(GET_ANTIBIOTIC_AND_ANTIBIOGRAM_REPORT, {
    variables: { filename },
    fetchPolicy: "network-only",
  });

  // Extract fetched data
  const antibiogramReport = data?.getAntiBiogramReport;
  const antibioticsList = data?.getAntibiotics?.data || [];
  const reportData = antibiogramReport?.data;
  const reportParameters = reportData?.reportParameters;

  const reportHeading = getReportHeading(
    reportParameters?.quarter,
    reportParameters?.year,
    reportParameters?.siteGroup,
  );

  const handlePrint = () => {
    // Apply styles and wait a bit for the UI to update
    setTimeout(() => {
      window.print();
    }, 300);
  };

  return (
    <Box>
      <AppBar elevation={0} className={styles.headerBar}>
        <MainNavigation
          showBackButton
          mainHeader={HospitalReportsStyledHeader()}
          backText="Hospital Reports"
          backPath="/hospital-reports"
          hideSeparator={true}
          isHospitalReports
          displayACL={isDesktop}
        />
      </AppBar>

      <div className={`${styles.hideForPrinting}`} style={{ height: offsetHeight }} />

      {/* Conditionally render Loading and Error inside JSX, not before return */}
      {loading ? (
        <Loading mt={4} textAlign="center" />
      ) : error ? (
        <Typography color="error" textAlign="center" mt={4}>
          Error: {error.message}
        </Typography>
      ) : (
        <>
          {/* Centered Heading */}
          <Grid container justifyContent="center" sx={{ textAlign: "center", mt: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="bold">
                {reportData?.hospitalName}
              </Typography>
              <Typography variant="h5" sx={{ mt: 1 }}>
                {reportHeading}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" sx={{ mt: 2, px: 3, pb: 2 }} className={styles.hideForPrinting}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{ mr: 2, textTransform: "none" }}
                startIcon={<PrintIcon />}
                onClick={handlePrint}
              >
                Print Report
              </Button>
              <Button variant="contained" color="secondary" sx={{ textTransform: "none" }} startIcon={<DownloadIcon />}>
                Download
              </Button>
            </Grid>
          </Grid>

          {/* Responsive Table Container */}
          <TableContainer
            sx={{
              mt: 2,
              px: 2,
              pb: 4,
              maxWidth: "calc(100% - 40px)",
              overflowX: isSmallScreen ? "auto" : "visible",
            }}
          >
            <Table sx={{ borderCollapse: "collapse", width: "100%", minWidth: "900px" }}>
              <Header siteGroup={reportParameters?.siteGroup} antibiotics={antibioticsList} />
              <Body antibiotics={antibioticsList} antibiogramData={reportData?.antibiogramData} />
            </Table>
            <Footer reportParams={reportParameters} />
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default AntiBiogramReportPage;
