import React from "react";
import TableBody from "@mui/material/TableBody";
import FirstRow from "./FirstRow";
import SecondRow from "./SecondRow";

import { BodyProps } from "../types/AntiBiogramTypes";

const Body: React.FC<BodyProps> = ({ antibiotics, antibiogramData }) => {
  return (
    <TableBody>
      {antibiogramData.map((row, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <FirstRow row={row} antibiotics={antibiotics} rowIndex={rowIndex} />
          <SecondRow row={row} antibiotics={antibiotics} rowIndex={rowIndex} />
        </React.Fragment>
      ))}
    </TableBody>
  );
};

export default Body;
