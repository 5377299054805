import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Card, CardContent, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MainNavigation } from "../MainNavigation/MainNavigation";
import AppBar from "@mui/material/AppBar";
import { useHistory } from "react-router-dom";
import styles from "./HospitalReports.module.css";
import theme from "../theme";
import { DataGrid, GridColDef, GridSearchIcon, GridSortModel } from "@mui/x-data-grid";
import { useLazyQuery, useMutation } from "@apollo/client";
import authService from "../../services/authService";
import Grid from "@mui/material/Grid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SummarizeIcon from "@mui/icons-material/SummarizeOutlined";
import thm from "../theme";
import ArticleIcon from "@mui/icons-material/Article";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import AppContext from "../../context/AppContext";
import { downloadHelper } from "../../utils/downloadHelper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getHospitalReportLink } from "./types/getHospitalReportLink";
import getHospitalReportUrlQuery from "./GetHospitalReportPdfQuery";
import HospitalReportsStyledHeader from "./HospitalReportsStyledHeader";
import getHospitalReportsQuery from "./GetHospitalReportsQuery";
import {
  getHospitalReports,
  getHospitalReports_getAssignedHospitalReports,
  getHospitalReports_getReportTypes,
  getHospitalReports_getReportYears,
  getHospitalReports_getUserHospitalReports_rows,
} from "./types/getHospitalReports";
import Loading from "../Loading/Loading";

dayjs.extend(utc);

export type HospitalReportsProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
  downloadcsv: {
    color: thm.colorSchemes.light.palette.primary.main + " !important",
    marginTop: "15px",
    cursor: "pointer",
  },
  disabledDownload: {
    color: "grey",
    marginTop: "15px",
    cursor: "not-allowed",
  },
  disabledButton: {
    color: "grey",
    marginTop: "15px",
    cursor: "not-allowed",
  },
  reportButton: {
    color: thm.colorSchemes.light.palette.primary.main + " !important",
    marginTop: "15px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  clearButton: {
    cursor: "pointer",
  },
  header: {
    color: theme.colorSchemes.light.palette.primary.main,
    fontWeight: "bold",
  },
  welcomeLogo: {
    width: "400px",
    height: "400px",
  },
  container: {
    width: "400px",
    height: "400px",
    position: "relative",
  },
  shape: {
    borderStyle: "solid",
    msTransform: "rotate(360deg)",
    oTransform: "rotate(360deg)",
    webkitTransform: "rotate(360deg)",
    transform: "rotate(360deg)",
    borderColor: "transparent " + theme.colorSchemes.light.palette.primary.dark + " transparent transparent",
    float: "right",
    right: "0px",
    height: "0px",
    width: "0px",
    borderWidth: "0 120px 120px 0",
    position: "absolute",
    display: "flex",
  },
  shapeText: {
    transform: "rotate(45deg)",
    color: "#FFF",
    fontSize: "20px",
    fontWeight: "bold",
    position: "relative",
    right: "-60px",
    top: "5px",
    textAlign: "center",
    margin: "auto",
  },
  resetButtonMob: {
    backgroundColor: "#979797 !important",
    marginLeft: "2px",
    color: "#ffffff",
  },
  clearIcon: {
    opacity: "0",
  },
  input: {
    height: "40px",
  },
  handleFilename: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginLeft: "10px",
  },
}));

const HospitalReportsPage: React.FC<HospitalReportsProps> = () => {
  const classes = useStyles();
  const { isDesktop } = useContext(AppContext);
  const [tableData, setTableData] = useState<getHospitalReports_getUserHospitalReports_rows[]>([]);
  const [tableDataCount, setTableDataCount] = useState<number>(0);
  const [tableDataPage, setTableDataPage] = useState<number>(0);
  const [reportid, setReportid] = useState<number[]>(authService.getHospitalReportList());
  const [assignedData, setAssignedData] = useState<getHospitalReports_getAssignedHospitalReports[]>([]);
  const [reportTypeData, setReportTypeData] = useState<getHospitalReports_getReportTypes[]>([]);
  const [reportYearData, setReportYearData] = useState<getHospitalReports_getReportYears[]>([]);
  const [filename, setFilename] = useState<string>("");
  const [selectedType, setSelectedType] = useState<number>(0);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [sortModel, setSortModel] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState<number>(50);
  const [selectedFile, setSelectedFile] = useState<number>(0);
  const mobility = authService.isMobility();
  const offsetHeight = !isDesktop || mobility ? 162 : 138;

  let action = "";
  let value = "";

  const [getHospitalReportsQry, { loading, error: reportListError }] = useLazyQuery<getHospitalReports>(
    getHospitalReportsQuery,
    {
      fetchPolicy: "network-only",
      onCompleted: (data: getHospitalReports) => {
        if (data) {
          setTableData(data.getUserHospitalReports.rows);
          setTableDataCount(data.getUserHospitalReports.rowCount);
          setAssignedData(data.getAssignedHospitalReports);
          setReportTypeData(data.getReportTypes);
          setReportYearData(data.getReportYears);
        }
      },
    },
  );

  const [getHospitalReportUrl, { loading: reportLinkLoading, error: reportLinkError, variables: currVariables }] =
    useLazyQuery<getHospitalReportLink>(getHospitalReportUrlQuery, {
      fetchPolicy: "network-only",
      onCompleted: (data: { getHospitalReportLink: any }) => {
        const id = currVariables?.id;

        if (data && data.getHospitalReportLink) {
          action = "HOSPITAL_REPORT_DOWNLOAD";
          value = JSON.stringify({ reportid: id });
          setSelectedFile(0);

          auditLog({
            variables: {
              audit: {
                value,
                action,
              },
            },
          });
        }
      },
    });

  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  useEffect(() => {
    setTableData([]);
    getHospitalReportsQry({
      variables: {
        reportid: reportid,
        pageSize: pageSize,
        page: tableDataPage,
        filename: filename,
        reporttype: selectedType,
        reportyear: selectedYear,
        sort: sortModel,
      },
    });
  }, [sortModel, tableDataPage, pageSize]);

  useEffect(() => {
    if (tableData.length <= 0) {
      getHospitalReportsQry({
        variables: {
          reportid: reportid,
          pageSize: pageSize,
          page: tableDataPage,
          filename: filename,
          reporttype: selectedType,
          reportyear: selectedYear,
          sort: sortModel,
        },
      });
    }
  }, []);

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    if (sortModel && sortModel.length > 0) {
      setSortModel([sortModel[0].field, sortModel[0].sort || ""]);
    }
  }, []);

  const handleSearch = () => {
    setTableData([]);
    getHospitalReportsQry({
      variables: {
        reportid: reportid,
        pageSize: pageSize,
        page: tableDataPage,
        filename: filename,
        reporttype: selectedType,
        reportyear: selectedYear,
        sort: sortModel,
      },
    });
  };

  const handleClear = () => {
    setReportid(authService.getHospitalReportList());
    setFilename("");
    setSelectedType(0);
    setSelectedYear(0);
    setTableData([]);
    setTableDataCount(0);
    setTableDataPage(0);
    getHospitalReportsQry({
      variables: {
        reportid: authService.getHospitalReportList(),
        pageSize: pageSize,
        page: 0,
        filename: "",
        reporttype: 0,
        reportyear: 0,
        sort: sortModel,
      },
    });
  };

  const handleDownloadClick = async (event: React.MouseEvent, params: any) => {
    const id = params.id;
    setSelectedFile(id);

    const res = await getHospitalReportUrl({
      variables: {
        id: id,
      },
    });

    if (res.data?.getHospitalReportLink.url) {
      fetch(res.data?.getHospitalReportLink.url)
        .then((res) => res.blob())
        .then((blob) => {
          const filename = params.row && params.row.filename ? params.row.filename : params.filename;
          downloadHelper(blob, filename);
        });
      //presign url not able to use saveas, enabled cors on s3
      //https://github.com/eligrey/FileSaver.js/issues/483
    }
  };

  const history = useHistory();

  const isReportTypeAllowed = [1, 13, 14].includes(selectedType);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      sortable: false,
      flex: 0.1,
      renderCell: () => {
        return (
          <>
            <ArticleIcon className={classes.downloadcsv} fontSize="small"></ArticleIcon>
          </>
        );
      },
    },
    {
      field: "reportcreated",
      headerName: "Created",
      flex: 0.5,
      valueFormatter: (value) => (value ? dayjs(value).format("DD MMM YYYY HH:mm:ss") : ""),
    },
    {
      field: "filename",
      headerName: "Filename",
      flex: 4,
      renderCell(params) {
        return params?.value as string;
      },
    },
    {
      field: "",
      headerName: "Download",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => {
        const filename = params.row.filename as string;
        const allowDownload = filename.endsWith(".pdf") || filename.endsWith(".xlsx") || filename.endsWith(".xlx");

        return (
          <>
            {reportLinkLoading && (params.id as number) === selectedFile ? (
              <Loading
                boxStyle={{ margin: "0" }}
                iconStyle={{ color: "grey", height: "20px", width: "20px", marginLeft: "-68px", marginTop: "15px" }}
              />
            ) : (
              <SaveAltIcon
                className={allowDownload && !reportLinkLoading ? classes.downloadcsv : classes.disabledDownload}
                fontSize="medium"
                onClick={(event) => {
                  if (allowDownload && !reportLinkLoading) {
                    handleDownloadClick(event, params);
                  }
                }}
              ></SaveAltIcon>
            )}
            <SummarizeIcon
              className={isReportTypeAllowed ? classes.reportButton : classes.disabledButton}
              fontSize="medium"
              onClick={() => {
                if (isReportTypeAllowed) {
                  history.push(`hospital-reports/antibiogram-report/${filename}`);
                }
              }}
              style={{ cursor: isReportTypeAllowed ? "pointer" : "not-allowed" }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <Box>
        <AppBar elevation={0} className={styles.headerBar}>
          <MainNavigation
            showBackButton
            mainHeader={HospitalReportsStyledHeader()}
            backText="PATIENTS"
            backPath="/patients"
            hideSeparator={true}
            isHospitalReports
            displayACL={isDesktop ? true : false}
          />
        </AppBar>
        <div className={`${styles.hideForPrinting}`} style={{ height: offsetHeight }} />
      </Box>

      <Grid container sx={{ overflowY: "hidden", overflowX: "hidden", ml: 2, mr: 2, mt: 0, width: "auto" }}>
        <Grid item xs={12} sx={{ mt: 2 }}></Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={isDesktop ? 2.5 : 12} sx={{ paddingRight: "10px" }}>
              <Select
                id="reportid"
                value={reportid.length >= 2 ? 0 : reportid[0]}
                onChange={(event) => {
                  const value: number = event.target.value as number;
                  let newList: number[] = [];

                  if (value === 0) {
                    newList = authService.getHospitalReportList();
                  } else {
                    newList.push(value);
                  }

                  setReportid(newList);
                }}
                variant="outlined"
                className={styles.DateInput}
                size="small"
                fullWidth
                disabled={loading || reportLinkLoading}
                sx={{
                  "& .MuiSelect-select": {
                    width: "80%",
                  },
                }}
              >
                <MenuItem value={0}>Select Hospital Report</MenuItem>
                {assignedData.map((item: getHospitalReports_getAssignedHospitalReports) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={isDesktop ? 2.5 : 12} sx={{ paddingRight: "10px" }}>
              <Select
                id="reporttype"
                value={selectedType}
                onChange={(event) => {
                  const value: number = event.target.value as number;
                  setSelectedType(value);
                }}
                variant="outlined"
                className={styles.DateInput}
                size="small"
                fullWidth
                disabled={loading || reportLinkLoading}
                sx={{
                  "& .MuiSelect-select": {
                    width: "80%",
                  },
                }}
              >
                <MenuItem value={0}>Select Report Type</MenuItem>
                {reportTypeData.map((item: getHospitalReports_getReportTypes) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={isDesktop ? 2.5 : 12} sx={{ paddingRight: "10px" }}>
              <Select
                id="reportyear"
                value={selectedYear}
                onChange={(event) => {
                  const value: number = event.target.value as number;
                  setSelectedYear(value);
                }}
                variant="outlined"
                className={styles.DateInput}
                size="small"
                fullWidth
                disabled={loading || reportLinkLoading}
              >
                <MenuItem value={0}>Select Year</MenuItem>
                {reportYearData.map((item: getHospitalReports_getReportYears) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.reportyear}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={isDesktop ? 2.5 : 12} sx={{ paddingRight: "10px" }}>
              <TextField
                placeholder="Filename"
                id="filename"
                name="filaname"
                variant="outlined"
                className={styles.DateInput}
                onChange={(event) => {
                  setFilename(event.target.value);
                }}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    //Enter Key
                    e.preventDefault();
                    handleSearch();
                  }
                }}
                size="small"
                fullWidth
                value={filename}
                InputProps={{
                  className: classes.input,
                  startAdornment: (
                    <InputAdornment position="start">
                      <GridSearchIcon />
                    </InputAdornment>
                  ),
                }}
                disabled={loading || reportLinkLoading}
              />
            </Grid>
            <Grid item xs={isDesktop ? 2 : 12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={styles.submitButton}
                onClick={handleSearch}
                disabled={loading || reportLinkLoading}
              >
                Search
              </Button>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                className={styles.submitButton}
                onClick={handleClear}
                disabled={loading || reportLinkLoading}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}></Grid>
        <Grid item xs={12} sx={{ mr: 1, ml: 1, minHeight: "70vh", margin: "0 0 0 8px" }}>
          <Box style={{ height: "100%", width: "100%", border: "none" }}>
            {isDesktop ? (
              <DataGrid
                sx={{
                  ".hightBackground": { background: "var(--mui-palette-primary-light)" },
                  "&, [class^=MuiDataGrid]": { border: "none" },
                  "& .MuiDataGrid-iconSeparator": {
                    display: "none",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                  },
                  "& .MuiDataGrid-main & .MuiDataGrid-root": {
                    display: "none",
                  },
                  "& .MuiTablePagination-select": { paddingRight: "24px" },
                  "& .MuiDataGrid-columnHeader:focus": {
                    outline: "none",
                  },
                }}
                getRowClassName={(params) => {
                  return params.row.viewCount == 0 ? "hightBackground" : "";
                }}
                paginationModel={{ page: tableDataPage, pageSize: pageSize }}
                onPaginationModelChange={(paginationModel) => {
                  setTableDataPage(paginationModel.page);
                  setPageSize(paginationModel.pageSize);
                }}
                disableColumnMenu
                rows={tableData}
                columns={columns}
                paginationMode="server"
                rowCount={tableDataCount}
                loading={loading}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            ) : (
              <Box>
                {loading ? (
                  <Loading boxStyle={{ margin: "0" }} iconStyle={{ color: "primary", marginTop: "15px" }} />
                ) : (
                  tableData.map((data, index) => (
                    <Card key={index} className={styles.mobileCardLayout}>
                      <CardContent className={styles.mobileCard}>
                        <Box className={styles.mobileRow}>
                          <Box>
                            <ArticleIcon className={classes.downloadcsv} fontSize="small"></ArticleIcon>
                          </Box>
                          <Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography variant="body2">Created</Typography>
                              <Typography variant="body2">
                                {data.reportcreated ? dayjs(data.reportcreated).format("DD MMM YYYY HH:mm:ss") : ""}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className={styles.mobileCell}>
                          <Typography variant="body2">Filename</Typography>
                          <Typography variant="body2" className={classes.handleFilename}>
                            {data.filename as string}
                          </Typography>
                        </Box>
                        <Box className={styles.mobileCell}>
                          <Typography variant="body2">Download</Typography>
                          {reportLinkLoading && data.id === selectedFile ? (
                            <Loading
                              boxStyle={{ margin: "0" }}
                              iconStyle={{
                                color: "grey",
                                height: "20px",
                                width: "20px",
                                marginLeft: "-26px",
                                marginTop: "15px",
                              }}
                            />
                          ) : (
                            <SaveAltIcon
                              className={
                                (data.filename.endsWith(".pdf") ||
                                  data.filename.endsWith(".xlsx") ||
                                  data.filename.endsWith(".xlx")) &&
                                !reportLinkLoading
                                  ? classes.downloadcsv
                                  : classes.disabledDownload
                              }
                              fontSize="medium"
                              onClick={(event) => {
                                if (
                                  (data.filename.endsWith(".pdf") ||
                                    data.filename.endsWith(".xlsx") ||
                                    data.filename.endsWith(".xlx")) &&
                                  !reportLinkLoading
                                ) {
                                  handleDownloadClick(event, data);
                                }
                              }}
                            ></SaveAltIcon>
                          )}

                          <SummarizeIcon
                            className={isReportTypeAllowed ? classes.reportButton : classes.disabledButton}
                            fontSize="medium"
                            onClick={() => {
                              if (isReportTypeAllowed) {
                                history.push(`hospital-reports/antibiogram-report/${filename}`);
                              }
                            }}
                            style={{ cursor: isReportTypeAllowed ? "pointer" : "not-allowed" }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  ))
                )}
              </Box>
            )}
            {(reportListError || reportLinkError) && <ErrorPopover />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default HospitalReportsPage;
