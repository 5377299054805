import packageInfo from "../../../../../package.json";

export const getAntibioticHeaderStyle = (antibioticType: number, restriction: number) => {
  if (restriction === 1 && antibioticType === 2) {
    return { backgroundColor: "#cac7de", color: "black" }; // Restricted Antibiotic (Type 2)
  } else if (restriction === 1 && antibioticType === 1) {
    return { backgroundColor: "#cac7de", color: "black" }; // Restricted Antibiotic (Type 1)
  } else if (antibioticType === 2) {
    return { backgroundColor: "#e6e6e6", color: "black" }; // Restricted (General)
  } else {
    return { backgroundColor: "white", color: "black" }; // Unrestricted (Default)
  }
};

export const computeCellStyle = (resultType: string, percOfIsoSentvty: number | string) => {
  if (resultType === "S") {
    if (typeof percOfIsoSentvty === "number") {
      if (percOfIsoSentvty >= 90) return "morethan90";
      if (percOfIsoSentvty >= 70) return "morethan70";
      if (percOfIsoSentvty < 70) return "noBgColor";
    }
  }
  if (resultType === "R" || resultType === "R*") return "lessthan70";
  return "noBgColor";
};

export const computeCellContent = (resultType: string, percOfIsoSentvty: number | string, noOfIsolates: string) => {
  if (resultType === "S" && typeof percOfIsoSentvty === "number") {
    return `${percOfIsoSentvty}%`;
  }
  if (resultType === "R" || resultType === "R*") {
    return resultType;
  }
  if (noOfIsolates !== "0") {
    return "";
  }
  return "-";
};

export const getSiteGroupComment = (siteGroup: string): string => {
  switch (siteGroup) {
    case "URINE":
      return "Only include isolates where isolate # > 30, at least top 3 most common irrespective of count";
    case "NON-URINE":
    case "BLOOD":
      return "Only include isolates where isolate # > 30, at least top 5 most common irrespective of count";
    default:
      return "";
  }
};

export const getCopyrightText = (year: number): string => {
  const currentYear = new Date().getFullYear();
  return `eResults version ${packageInfo.version}. Copyright © 2000 - ${year || currentYear} Clinical Laboratories Pty Ltd. All rights reserved.`;
};

export const getReportHeading = (quarter: number, year: number, siteGroup: string): string => {
  let dateRange = "";

  switch (quarter) {
    case 1:
      dateRange = `January 1st - March 31st, ${year}`;
      break;
    case 2:
      dateRange = `April 1st - June 30th, ${year}`;
      break;
    case 3:
      dateRange = `July 1st - September 30th, ${year}`;
      break;
    case 4:
      dateRange = `October 1st - December 31st, ${year}`;
      break;
    default:
      dateRange = `January 1st - December 31st, ${year}`;
      break;
  }

  return `Cumulative Antibiogram for ${siteGroup} Isolates (${dateRange})`;
};
