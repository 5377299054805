import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import styles from "../AntiBiogramReport.module.css";
import { styled } from "@mui/system";

import { AntibiogramRow, Antibiotic, Isolate } from "../types/AntiBiogramTypes";

const StyledTableCell = styled(TableCell)({
  border: "3px solid silver",
  fontFamily: "Arial",
  fontSize: "18px",
  textAlign: "center",
  padding: "10px",
  whiteSpace: "nowrap",
});

const SecondRow: React.FC<{ row: AntibiogramRow; antibiotics: Antibiotic[]; rowIndex: number }> = ({
  row,
  antibiotics,
  rowIndex,
}) => {
  return (
    <TableRow>
      {/* First two columns are already merged in FirstRow */}
      {/* Third column: "total #" */}
      <StyledTableCell className={styles.noBgColor}>total #</StyledTableCell>

      {/* Antibiotic columns */}
      {antibiotics.map((antibiotic) => {
        const isolate: Isolate | undefined = row.isolatesList.find(
          (isolate) => isolate.antimicrobial === antibiotic.groupCode,
        );
        const organismCount = isolate?.organismCount?.toString() || "-";

        return (
          <StyledTableCell key={`${rowIndex}-${antibiotic.id}`} className={styles.noBgColor}>
            {organismCount}
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
};

export default SecondRow;
